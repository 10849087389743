.form_action_btnflex {
  padding: 15px;
}
.submit_btn_flex {
  display: contents;
}
.tablehead {
  padding:10px 2px  !important;
  font-size: 16px !important;
  /* text-align: center !important; */
  vertical-align: middle !important;
}

.tablerow {
  padding:2px !important;
  font-size: 16px !important;
  text-align: center !important;
  vertical-align: middle !important;
}
