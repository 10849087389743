.section_padding {
  padding: 0.5rem;
}

.action_flex {
  display: flex;
  justify-content: space-between;
}

.modal-header button {
  display: none;
}

.tablehead {
  padding: 10px 2px !important;
  font-size: 13px !important;
  text-align: center !important;
  vertical-align: middle !important;
}
.css-telxh8-container{
  width: 100% !important;
}
.react-datepicker-wrapper input{
  height: 38px !important;
  border: 1px solid #ced4da;
  /* color: #ced4da !important; */
}
.tablerow {
  padding: 2px !important;
  font-size: 15px !important;
  text-align: center !important;
  vertical-align: middle !important;
}

/* .actions{
  padding-inline: 7px !important;
} */

.dynamic-input {
  width: 100%;
}

.inputs {
  width: 23%;
  padding: 0 !important;
}

.add {
  width: 5%;
  padding: 5px !important;
}

.add i {
  vertical-align: middle;
}

.clientinfo-box {
  border: 2px solid rgb(214, 214, 214);
  padding: 20px;
  border-radius: 5px;
}

.inputrow {
  width: 100%;
  margin: auto;
}

.actionstaff {
  width: fit-content !important;
  padding: 3px !important;
}



@media screen and (max-width: 500px) {

  .datepicker {
    display: flex;
    flex-direction: column;

  }

  .datepicker label {
    padding: 0 !important;
  }
  .search-bar{
    margin-top: 10px !important;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width:992px) {
  /* .datepicker {
    display: flex;
    flex-direction: column;

  } */

  .search-bar{
    margin-top: 10px !important;
    width: 100%;
  }
}