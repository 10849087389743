.brand-link .brand-image {
  float: left;
  line-height: 0.8;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  margin-top: -7px;
  max-height: 51px;
  width: auto;
}
.apexcharts-toolbar {
  /* position: absolute; */
  z-index: 11;
  max-width: 176px;
  text-align: right;
  border-radius: 3px;
  padding: 0 6px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
label {
  color: #5d5f62;
}
sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: #1e336a;
  color: #fff;
  box-shadow: 0 -5px 3px -3px #ccc, 0 5px 3px -3px black;
}
/* box-shadow: 0 -5px 3px -3px #ccc, 0 5px 3px -3px black;
} */
.nav-link.active {
  background-color: red;
  color: #fff;
  box-shadow: 0 -5px 3px -3px black, 0 5px 3px -3px black;
}
.searchbar_size {
  border-radius: 5px;
  margin-right: 17px;
  border: 0.2px solid gray;
  width: 14rem;
}
.pointer_cursor {
  cursor: pointer;
}
.btn_style {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.required::after {
  content: '*';
  position: relative;
  font-size: inherit;
  color: red;
  padding-left: 0.25rem;
  font-weight: 600;
}
.react-datepicker-wrapper input {
  border: 0.3px solid black;
  height: 30px;
  border-radius: 5px;
  margin-top: 9px;
}
select {
  height: 50px;
  overflow-y: scroll;
}
.scroll_setting {
  overflow-y: auto;
}
.sticky-button {
  position: sticky;
  bottom: 20px;
  width: 100%;
  padding: 10px;
  background-color: #343a40;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.main-sidebar {
  overflow: inherit;
}
.card {
  height: 100%;
}
.green {
  color: green;
}
.red {
  color: red;
}
.table-wrapper {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: hidden; /* Hide vertical scrollbar */
  max-height: 100vh; /* Set maximum height to full viewport height */
}

/* Customizing scrollbar appearance */
.table-wrapper::-webkit-scrollbar {
  width: 0; /* This will hide the scrollbar in webkit browsers like Chrome and Safari */
}

/* For Firefox */
.table-wrapper {
  scrollbar-width: none;
}
