.activee {
  background-color: blue;
}

.background_color {
  background-color: #343a40;
}

.NavLink.hover {
  color: white;
}

/* .icons_color {
  background-color: #c2c7d0;
} */
.dropdown-item:hover {
  background-color: #1e336a;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-y: hidden;
  /* Disable vertical scrolling for the entire body */
}


.mainfooter {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
  /* padding-bottom: 1.4rem; */
  width: 100%;
  position: fixed;
  bottom: 0 !important;
  z-index: 1000 !important;
  /* margin-left: 250px; */
}

.sidebar {
  height: 100vh !important;
}

.button {
  display: block;
  margin-right: 1rem;
  padding: 7px 9px;
  width: 8rem;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 4px;

}


.content-wrapper {
  margin-left: 250px;
  /* Adjust based on the width of your sidebar */
  width: auto;
  /* Adjust based on the width of your sidebar */
  height: calc(100vh - 50px);
  /* Adjust based on the height of your footer */
  overflow-y: auto;
  /* Enable vertical scrolling for the content area */
  /* Add other styling properties for the content area */
}

.nav-link {

  padding-left: 18px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  font-size: 18px;
  font-weight: 500;
}

.nav-link a {
  font-size: 18px;
}

.content {
  margin-bottom: 70px !important;
}

.nav-item {
  margin: 4px 0 !important;
}

/* @media screen and (max-width: 576px) {
  .content-wrapper {
    overflow-y: scroll;
  }

} */