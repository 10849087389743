/* .apexcharts-canvas {
    margin: auto;
    width: 100% !important;
}

#SvgjsSvg62802 {
    width: 100%;
    display: block !important;
    margin: auto !important;

}
foreignObject{
    
} */
.card-body {
    /* min-height: 450px !important; */
}

.apexcharts-legend {
    justify-content: center !important;
    /* align-items: center !important; */
}

td a {
    text-decoration: none !important;
    color: black;
}

hr{
    transform: rotate(360deg) !important;
    height: 10vh;
}

.box-main{
    padding: 10px !important;
}



@media (max-width: 500px) {
    .apexcharts-text {
        font-size: 10px !important;
    }

    .apexcharts-canvas {
        display: block !important;
        margin: auto !important;
        /* max-height:405px !important */

    }

}