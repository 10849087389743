.active_status_flex {
  display: flex;
  justify-content: start;
}

.tableheadstaff {
  padding: 10px 2px !important;
  font-size: 13px !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.tablerowstaff {
  padding: 2px !important;
  font-size: 15px !important;
  text-align: center !important;
  vertical-align: middle !important;
}

td .actionstaff {
  /* width: 40px !important; */
  padding: 0px !important;
  width:fit-content !important;
  box-sizing: border-box !important;
}
.actionstaff i:hover{
  color: #007aff;
}

.profile_image_profile {
  width: 60px;
  /* Adjust the width as needed */
  height: 60px;
  /* Adjust the height as needed */
  overflow: hidden;
  border-radius: 50%;
}

.profile_image_profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.react-datepicker__close-icon {
  padding-top: 7px !important;
  /* display: none; */
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  /* Adjust the background color and opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Set a high z-index to ensure it appears on top of other elements */
}

.form-control {
  display: block !important;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out;
  box-shadow: .15s ease-in-out;
}