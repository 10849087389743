.spinner-back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner-cont {
    width: 100%; /* Adjust as needed */
    height: 100%; /* Adjust as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: white; */
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  }
  